<template>
    <div :class="['min-vh-100 d-flex align-items-center justify-content-center', backgroundClass]">
        <div class="text-center p-4">
            <img :src="imageSrc" class="img-fluid" width="60%" alt="Error Image" />
            <h4 class="mt-5 mb-3 font-weight-bold">
                {{ errorMessage }}
            </h4>
            <span class="text-muted" v-html="errorDescription"></span><br>
            <div class="text-muted" style="margin-top: 2cap;"> CODE : {{ errorCode }}</div>
        </div>
        <div class="fixed-bottom text-center w-100 p-3">
            <span class="text-muted">Powered by <br></span>
            <img class="mt-2" src="/img/SilomPOS_logo.png" width="25%">
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        errorCode() {
            // Error Code
            // 1 => QR Expired
            // 2 => No QR in DB
            // 3 => Payment Success
            // 4 => Scan Static on Dynamic Setting
            // 5002 => Table has Moved
            return this.$route.query.errorCode || 2; // Default to 0
        },
        errorMessage() {
            switch (parseInt(this.errorCode)) {
                case 1:
                    return this.$t("errorMessages.qrExpired");
                case 2:
                    return this.$t("errorMessages.qrNotFound");
                case 3:
                    return this.$t("errorMessages.paymentSuccess");
                case 4:
                    return this.$t("errorMessages.invalidQrType");
                case 5002:
                    return this.$t("errorMessages.tableMoved");
                default:
                    return this.$t("errorMessages.qrNotFound");
            }
        },
        errorDescription() {
            switch (parseInt(this.errorCode)) {
                case 1:
                    return this.$t("errorDescriptions.qrExpired");
                case 2:
                    return this.$t("errorDescriptions.qrNotFound");
                case 3:
                    return this.$t("errorDescriptions.paymentSuccess");
                case 4:
                    return this.$t("errorDescriptions.invalidQrType");
                case 5002:
                    return this.$t("errorDescriptions.tableMoved");
                default:
                    return this.$t("errorDescriptions.qrNotFound");
            }
        },
        imageSrc() {
            switch (parseInt(this.errorCode)) {
                case 1:
                    return "/img/staff_assist.png"; // QR Expired
                case 2:
                    return "/img/receipt_empty.png"; // QR Not Found in DB
                case 3:
                    return "/img/thanks.png"; // QR Not Found in DB
                case 4:
                    return "/img/staff_assist.png";  // Scan Static on Dynamic Setting
                case 5002:
                    return "/img/receipt_empty.png";  // Table has moved
                default:
                    return "/img/receipt_empty.png/"; // Paid
            }
        },
        backgroundClass() {
            switch (parseInt(this.errorCode)) {
                case 1:
                case 2:
                    return "bg-white"; // Yellow background for errors
                default:
                    return "bg-white"; // Green background for success
            }
        }
    }
};
</script>

<style scoped>
.bg-warning {
    background-color: #ffcc00 !important;
}

.bg-white {
    padding: 4cap;
    background-color: #ffffff !important;
}
</style>
